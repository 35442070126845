/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';

// UI lib components

// UI local components
import axios from 'axios';
import PageLayout from '../../../shared/PageLayout';
import Seo from '../../../shared/Seo';
import OfferDetails from '../../../page-components/Offers/OfferDetails';
import ApplicationForm from '../../../page-components/Offers/Application';
import Contact from '../../../page-components/home/Contact';
import { OFFERS_ENDPOINT } from '../../../shared/Helpers/api';

// styles

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Offer({ id }) {
  /* ********************************** HOOKS ********************************* */
  const [offerData, setOfferData] = React.useState();

  React.useEffect(() => {
    async function getData() {
      try {
        const { data } = await axios.get(`${OFFERS_ENDPOINT}/${id}`);
        setOfferData(data?.data?.attributes);
      } catch (error) {
        navigate('/');
      }
    }
    getData();
  }, [id]);

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <OfferDetails {...offerData} />
      <ApplicationForm offer={id} />
      <Contact />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Offers", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

Offer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Offer;
